import Vue from 'vue'
import store from '@/store'
import cognitoUtil from '@/commons/cognitoUtil'
import {
  CognitoUser, CognitoUserSession, CognitoIdToken, CognitoRefreshToken,
} from 'amazon-cognito-identity-js'
import axios from 'axios'

interface YlabUser {
  isLogin: boolean,
  cognito?: CognitoUser,
  idToken?: string,
  refreshToken?: CognitoRefreshToken,
  userId?: string,
  userName?: string,
  payment?: string
}

export const authorize = async (to: any, from: any, next: any) => {
  
  let user: YlabUser = {
    isLogin: false
  }
  Vue.prototype.$user = user

  let c: cognitoUtil = new cognitoUtil()

  // セッション取得
  let cognito: CognitoUser = await c.getSession()
  if (cognito) {
    let session: CognitoUserSession | null = cognito.getSignInUserSession()
    if (session!.isValid()) {
      let idToken: CognitoIdToken = session!.getIdToken()
      user = {
        isLogin: true,
        cognito: cognito,
        idToken: idToken.getJwtToken(),
        refreshToken: session!.getRefreshToken(),
        userId: cognito.getUsername(),
        userName: idToken.payload.nickname
      }
    } else {
      console.error('セッション有効期限切れ')
    }

    // 支払情報取得
    let ss: Storage = sessionStorage
    const SESSION_PAYMENT = 'session_use_payment'
    // let pay = ss.getItem(SESSION_PAYMENT)
    // if (!pay) {
      const getPayment = () => new Promise<string>((resolve, reject) => {
        const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user'
        axios.get(jsonUrl, {
          headers: {
            'Authorization': user.idToken
          }
        })
        .then(res => {
          resolve(res.data.payment)
        })
        .catch(err => {
          console.error(err)
          resolve()
        })
      })
      user.payment = await getPayment()
      // ss.setItem(SESSION_PAYMENT, user.payment)
    // }
  }

  Vue.prototype.$user = user

  if (to.meta.needLogin && !user.isLogin) {
    next({ path: '/login', query: { url: to.fullPath }})
  } else {
    next()
  }
}