var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-component" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "dark", variant: "info" } },
        [
          _c("b-navbar-brand", [
            _c("div", { attrs: { id: "logo" } }, [
              _c(
                "div",
                { attrs: { id: "logo-box" } },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", {
                      attrs: { src: require("../assets/logo.png"), alt: "" }
                    })
                  ])
                ],
                1
              ),
              _vm.payment == "free"
                ? _c("div", { attrs: { id: "trial-message" } }, [
                    _vm._v("【 １ヶ月無料期間中 】")
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { attrs: { id: "right-area" } }, [
            _vm.isSignin
              ? _c(
                  "div",
                  {
                    attrs: { id: "notice-box" },
                    on: {
                      click: function($event) {
                        return _vm.callRouterPush("/notice/noticeList")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-bell" }),
                    _vm.isNoticeBadgeShow
                      ? _c("div", { attrs: { id: "notice-badge" } }, [
                          _vm._v(_vm._s(_vm.unreadNoticeCount))
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                attrs: { id: "toggle-box" },
                on: { click: _vm.openDrawerModal }
              },
              [_c("span")]
            )
          ]),
          _c(
            "div",
            { staticClass: "float-right", attrs: { id: "pc-menu" } },
            [
              _c(
                "b-collapse",
                {
                  staticClass: "justify-content-end",
                  attrs: { id: "nav-collapse", "is-nav": "" }
                },
                [
                  _c(
                    "b-navbar-nav",
                    [
                      _vm.isSignin
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: "/notice/noticeList" } },
                            [
                              _vm._v("お知らせ"),
                              _vm.isNoticeBadgeShow
                                ? _c(
                                    "b-badge",
                                    { attrs: { variant: "info" } },
                                    [_vm._v(_vm._s(_vm.unreadNoticeCount))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSignin
                        ? _c(
                            "b-nav-item-dropdown",
                            { attrs: { text: "アカウント設定", right: "" } },
                            [
                              _c(
                                "b-dropdown-item",
                                { attrs: { to: "/user/edit/input" } },
                                [_vm._v("情報の確認・修正")]
                              ),
                              _c(
                                "b-dropdown-item",
                                { attrs: { to: "/user/password/input" } },
                                [_vm._v("パスワード変更")]
                              ),
                              _c(
                                "b-dropdown-item",
                                { attrs: { to: "/user/mail/send_auth" } },
                                [_vm._v("メールアドレス変更")]
                              ),
                              _vm.payment == null || _vm.payment == "free"
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { to: "/user/payment/add_input" }
                                    },
                                    [_vm._v("有料プランに変更")]
                                  )
                                : _c(
                                    "b-dropdown-item",
                                    { attrs: { to: "/user/payment/info" } },
                                    [_vm._v("支払情報")]
                                  )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-nav-item", { attrs: { to: "/users/userSearch" } }, [
                        _vm._v("ユーザー検索")
                      ]),
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { text: "利用ガイド", right: "" } },
                        [
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/user_guide" } },
                            [_vm._v("ご利用ガイド")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/faq" } },
                            [_vm._v("FAQ")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/terms" } },
                            [_vm._v("利用規約")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/inquiry_form/input" } },
                            [_vm._v("お問い合わせ")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/company" } },
                            [_vm._v("運営会社")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/legalnotice" } },
                            [_vm._v("免責事項")]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/law" } },
                            [_c("small", [_vm._v("特定商取引法の表示")])]
                          ),
                          _c(
                            "b-dropdown-item",
                            { attrs: { to: "/info/privacy" } },
                            [_c("small", [_vm._v("プライバシーポリシー")])]
                          )
                        ],
                        1
                      ),
                      !_vm.isLoginPage & _vm.isSignin
                        ? _c(
                            "b-nav-item",
                            { attrs: { href: "#" }, on: { click: _vm.exec } },
                            [_vm._v("ログアウト")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "error-modal",
                    title: "システムエラー",
                    "ok-only": ""
                  }
                },
                [
                  _c("div", [_vm._v("システムエラーが発生しました。")]),
                  _c(
                    "div",
                    [
                      _vm._v(
                        "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: "/inquiry_form/input" } },
                        [_vm._v("こちら")]
                      ),
                      _vm._v("よりお問い合わせください。")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }