import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import { authorize } from '@/router/before'

Vue.use(VueRouter)

interface PathSetting {
  // URLパス
  path: string,
  // ファイルパス（任意）
  file?: string,
  // タイトル（任意）
  title?: string,
  // ログイン必要有無（任意）
  needLogin?: boolean  // true:ログイン必要,
  // ネストコンポーネント（任意）
  children?: PathSetting[]
}

const path: PathSetting[] = [
  // URLパス
  // viewsディレクトリ配下のvueファイルのパスと合わせる
  { path: '*', file: '/notfound', title: 'ページが見つかりません' },
  { path: '/', file: '/home' },
  { path: '/rocky/search', title: '検索条件入力' },
  { path: '/rocky/map_search', title: '地図検索' },
  { path: '/rocky/rocky_list', title: '検索結果（岩場一覧）' },
  { path: '/rocky/all_rocky_list', title: '全岩場一覧' },
  { path: '/rockyInfo/:rockyId', title: '岩場情報' },
  { path: '/rockyInfo/:rockyId/checkingin_user_list', title: 'チェックイン中ユーザー一覧' },
  { path: '/rockyInfo/:rockyId/:rockId', title: '岩情報' },
  { path: '/rockyInfo/:rockyId/:rockId/:routeId', title: 'ルート情報' },
  { path: '/record/list', title: '記録一覧画面', needLogin: true},
  { path: '/record/edit', title: '記録作成画面', needLogin: true},
  { path: '/register_user/send_auth', title:'ユーザー登録'},
  { path: '/register_user/input_auth', title:'ユーザー登録'},
  { path: '/register_user/input', title:'ユーザー登録', needLogin: true},
  { path: '/register_user/test_pay', title:'ユーザー登録'},
  { path: '/register_user/complete', title:'ユーザー登録'},
  { path: '/register_user/complete_free', title:'ユーザー登録'},
  { path: '/user/edit/input', title:'ユーザー情報の確認・修正', needLogin: true},
  { path: '/user/edit/complete', title:'ユーザー情報の確認・修正(完了)'},
  { path: '/user/password/input', title:'パスワード変更', needLogin: true},
  { path: '/user/password/complete', title:'パスワード変更(完了)'},
  { path: '/user/mail/send_auth', title:'メールアドレス変更', needLogin: true},
  { path: '/user/mail/input_auth', title:'メールアドレス変更'},
  { path: '/user/mail/complete', title:'メールアドレス変更'},
  { path: '/user/password_reset/reset', title:'パスワード再設定'},
  { path: '/user/password_reset/input', title:'パスワード再設定'},
  { path: '/user/password_reset/complete', title:'パスワード再設定'},
  { path: '/user/payment/upd_input', title:'支払方法変更', needLogin: true},
  { path: '/user/payment/upd_complete', title:'支払方法変更'},
  { path: '/user/payment/add_input', title:'支払方法登録', needLogin: true},
  { path: '/user/payment/add_complate', title:'支払方法登録'},
  { path: '/user/payment/del_input', title:'支払情報削除', needLogin: true},
  { path: '/user/payment/del_complete', title:'支払情報削除'},
  { path: '/user/payment/info', title:'支払情報'},
  { path: '/users/userSearch', title: 'ユーザー検索' },
  { path: '/myPage/:userId', title: 'ユーザーマイページ', children: [
    { path: 'userInfoTop', title: 'ユーザーマイページトップ' },
    { path: 'badgeList', title: 'ステータスバッジ一覧' },
    { path: 'checkInRally', title: 'チェックインラリー' },
    { path: 'rockyList', title: '登攀した岩場一覧' }, 
    { path: 'allRouteList', title: '登攀したルート一覧' },
    { path: 'checkInHistoryList', title: 'チェックイン履歴' },
    { path: 'friendsList', title: '友達一覧' },
    { path: 'rockList', title: '登攀した岩一覧' }, 
    { path: 'routeDetail', title: '登攀したルート詳細' }, 
  ]},
  { path: '/unsubscribe/consent', title:'退会の手続き', needLogin: true},
  { path: '/unsubscribe/complete', title:'退会の手続き'},
  { path: '/notice/noticeList', title:'お知らせ一覧'},
  { path: '/inquiry_form/input', title:'問い合わせ入力'},
  { path: '/inquiry_form/confirm', title:'問い合わせ入力内容確認'},
  { path: '/inquiry_form/complete', title:'問い合わせ完了'},
  { path: '/feed_back/input', title:'フィードバック入力'},
  { path: '/feed_back/confirm', title:'フィードバック入力内容確認'},
  { path: '/feed_back/complete', title:'フィードバック完了'},
  { path: '/friend/friendRequest', title:'友達リクエスト', needLogin: true},
  { path: '/info/user_guide', title:'ご利用ガイド'},
  { path: '/info/guide_record_cehckin_process', title:'記録やチェックインの方法'},
  { path: '/info/faq', title:'FAQ'},
  { path: '/info/terms', title:'利用規約'},
  { path: '/info/company', title:'運営会社'},
  { path: '/info/legalnotice', title:'免責事項'},
  { path: '/info/law', title:'特定商取引法の表示'},
  { path: '/info/privacy', title:'プライバシーポリシー'},
  { path: '/info/contact-privacy', title:'個人情報の取り扱いについて'},
  { path: '/info/personal-privacy', title:'個人情報の取り扱いについて'},
  { path: '/login', title:'ログイン'},
  { path: '/logout', title:'ログアウト'},
  { path: '/description', title:'ヤマラボ紹介'},
  { path: '/blog', title:'ブログ一覧'},
  { path: '/blog/:date', title:'ブログ'},
  { path: '/blog/20200730', title:'ヤマラボリリースしました'},
  { path: '/blog/20201026', title:'御岳ボルダー'},
  { path: '/blog/20201120', title:'フクベ(瓢) ＆ 笠置山'},
  { path: '/blog/20201203', title:'フクベ・新川・大倉・白峰｜新しいトポ追加のお知らせ'},
  { path: '/blog/20210115', title:'笠置山｜新しいトポ追加のお知らせ'},
  { path: '/blog/20210330', title:'豊田'},

  // 開発用
  { path: '/sample/sample', title: 'サンプル', children: [
    { path: 'sub1', title: 'サブ1' },
    { path: 'sub2', title: 'サブ2', needLogin: true }
  ]},
  { path: '/sample/sample2', title: 'サンプル2', needLogin: true },
  { path: '/sample/sample/:id', title: 'サンプル3' },
  { path: '/sample/sample/:id/:subid', title: 'サンプル4' },
  { path: '/url', title: 'URL' },
  { path: '/sample/input_test', title: 'SAMテスト' },
]

const routes: RouteConfig[] = (() => {
  let r: RouteConfig[] = []
  path.forEach((p: PathSetting) => {

    let c: RouteConfig[] = []
    if (p.children) {
      p.children.forEach(value => {
        c.push({
          path: value.path,
          name: ((p.file) ? p.file : p.path).slice(1).replace(/\//g, '_') + '_' + ((value.file) ? value.file : value.path).replace(/\//g, '_'),
          component: () => import('../components' + ((p.file) ? p.file : p.path.replace(/:/g, '')) + '/' + ((value.file) ? value.file : value.path.replace(/:/g, '')) + '.vue'),
          meta: {
            title: value.title,
            needLogin: value.needLogin
          }
        })
      })
    }

    r.push({
      path: p.path,
      name: ((p.file) ? p.file : p.path).slice(1).replace(/\//g, '_'),
      component: () => import('../views' + ((p.file) ? p.file : p.path.replace(/:/g, '')) + '.vue'),
      meta: {
        title: p.title,
        needLogin: p.needLogin
      },
      children: c
    })
  })

  //console.log(r)

  return r
})()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {x: 0, y: 0 }
  }
})

router.beforeEach(async (to: Route, from: Route, next: any) => {
  // 認証情報確認
  await authorize(to, from, next)
})

router.afterEach((to: Route, from: Route) => {
  // ページタイトルセット
  document.title = ((to.meta && to.meta.title) ? to.meta.title + ' | ' : '') + process.env.VUE_APP_TITLE
})

export default router