<template lang="pug">
  div
    .modal-backdrop.fade.show
    .modal.fade.show
      .modal-dialog
        .modal-content
          .modal-body
            .text-center チェックイン
              button.close(@click="$emit('closeDialog')") ×　
            .my-4
            .mb-3.small(v-if="isRocky")
              div 現在地付近(500m以内)
            .my-5.text-center(v-if="message")
              i.fas.fa-exclamation-circle.text-warning.mr-2
              | {{ message }}
            .text-center
              b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
            div(v-if="isRocky")
              .title.float-left.mb-2.pl-2.col 岩場
              .small.mb-3.ml-2 まだ岩に到着していない場合はここからチェックイン！
              .justify-content-center.col
                div(v-for="item in rocky")
                  .card.mb-3
                    a.card-body.p-1(@click.prevent="checkin()")
                      div
                        img.rocky-img.float-left.mr-1(:src="getRockyImgThumbPath(rocky[0].imgPath)")
                      div.img-back
                      div {{rocky[0].rockyName}}
                      .small.text-black-50 {{rocky[0].address}}
              .float-left.title.mb-2.pl-2.col 岩
              .small.mb-3.ml-2 岩に到着したらここからチェックイン！
              .alert.alert-danger(v-if="banFlgMessage") {{ banFlgMessage }}
              .row.mx-1
                .col-6.p-2(v-for="item in data" @click.prevent="checkin(item.id,item.banFlg,item.name)")
                  .rock-img-list-box.position-relative.d-flex.justify-content-center.bg-dark
                    img.position-absolute.mw-100.h-100(:src="createImgPath(item.imgList[0].path)")
                    .rocky-ban.position-absolute.text-danger.p-1.font-weight-bold(v-if="item.banFlg") 登攀禁止
                    .rock-name.h-auto.p-1.position-absolute.small.text-white.w-100 {{item.name}}
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  //データオブジェクト
  data() {
    return {
      showModal: false,
      rocky: null,
      data: null,
      materialURL: process.env.VUE_APP_MATERIAL_URL,
      message: '',
      rockyId: '',
      isRocky: false,
      rockId: null,
      lat: '',
      lng:'',
      isLoading: true,
      rockyImgPath: null,
      banFlgMessage: '',
      isBanFlg: '',
      checkinRockName: '',
      iconPath: '',
    }
  },
  //メソッド
  methods: {
    //座標取得処理
    getPosition: () => new Promise((resolve, reject) => {
      if (!navigator || !navigator.geolocation) reject()
      navigator.geolocation.getCurrentPosition(
        pos => resolve(pos),
        err => reject(err),
        {
          enableHighAccuracy: true,
          maximumAge: 300000,  // 5分前まで
          timeout: 5000
        }
      )
    }),

    async search() {
      //座標取得
      let pos
      try {
        pos = await this.getPosition()
        this.message = ''
        if (process.env.NODE_ENV === 'development') { // 開発時用座標
          this.lat = process.env.VUE_APP_DEV_CHECKIN_LAT
          this.lng = process.env.VUE_APP_DEV_CHECKIN_LNG
          // this.lat = 36.469661
          // this.lng = 138.106474
        } else {
          this.lat = pos.coords.latitude
          this.lng = pos.coords.longitude
        }
      } catch (err) {
        console.log(err)
        this.isLoading = false
        this.message = '現在地を取得できませんでした。端末、もしくはブラウザの位置情報の許諾に関する設定をご確認ください。'
        return
      }

      //岩場のデータ取得
      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-rocky', {
        params: {
          "lat": this.lat,
          "lng": this.lng,
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.rocky = res.data
        this.rockyId = res.data[0].rockyId
        this.rockyName = res.data[0].rockyName

        //岩のデータ取得
        const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-rock'
        axios.get(jsonUrl, {
          params: {
            "rockyId": this.rockyId,
            "isLogin": this.$user.isLogin
          },
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(res => {
          this.data = res.data.rockList
          this.isLoading = false
          this.isRocky = true
        })
      })
      .catch(err => {
        console.log(err)
        this.message = '近くの岩場がみつかりませんでした。'
        this.isLoading = false
      })
    },
    getRockyImgThumbPath(imgPath){
      return this.getRockyThumbPath(imgPath,'120')
    },
    createImgPath(path){
      return this.getRockThumbPath(path,this.rockyId,'120')
    },
    //クリック時、チェックイン処理
    async checkin(id,banFlg,rockName) {

      this.rockId = id

      this.banFlgMessage = ''

      //登攀禁止の岩だった場合
      if(banFlg == true) {
        this.banFlgMessage = '登攀禁止の岩なので、チェックインできません。'
        return
      }

      //岩場選択時
      if(this.rockId == null) {
        this.rockId = 0
      }

      const url = process.env.VUE_APP_API_GATEWAY + '/api-checkin'
      const params = {
        rockyId: this.rockyId,
        rockId: this.rockId,
        lat: this.lat,
        lng: this.lng,
      }
      axios.post(url,params, {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        if(res.data.isLevelUp == false) {
          this.$emit('checkin', rockName ? rockName : this.rockyName)
          this.$emit('closeDialog')
        }else if(res.data.isLevelUp == true) {
          this.checkinRockName = rockName
          this.iconPath = res.data.iconPath
          this.$emit('levelUp', rockName ? rockName : this.rockyName, this.iconPath)
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
  },
  //インスタンス作成後
  created() {
    document.body.classList.add('modal-open')
    this.search()
  },
   // インスタンス破棄前
  beforeDestroy() {
    document.body.classList.remove('modal-open')
  },
  // コンポーネント
  components: {
  }
})
</script>

<style lang="stylus" scoped>
.modal
  display block
.title
  border-left: 6px solid #027EC3
.card
  :hover
    cursor pointer
.rock-img-list-box
  padding-top 75%
  :hover
    box-shadow 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2)
    cursor pointer
  :active
    opacity .8
  img
    top 0
  .rock-name
    bottom 0
    left 0
    background rgba(0, 0, 0, 0.5)
  .rocky-ban
    top 0
    left 0
    background rgba(222,225,227,0.6)
.rocky-img
  max-width 120px
  height auto
/* IE11対策 */
.justify-content-center
   -ms-flex-pack start !important
</style>