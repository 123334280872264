import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import global from './commons/global'

// BootstrapVue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// vue-analytics
import VueAnalytics from 'vue-analytics'
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueAnalytics, {
    id: 'UA-179805005-1',
    router
  })
}

Vue.config.productionTip = false
Vue.config.devtools = (process.env.NODE_ENV === 'development')
Vue.config.silent = (process.env.NODE_ENV === 'production')

if (process.env.NODE_ENV === 'development') {
  console.log(process.env)
}

Vue.mixin(global)

// Cognito管理用
Vue.prototype.$cognito = {}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
