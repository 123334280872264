var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Header", { attrs: { isSignin: _vm.isSignin, payment: _vm.payment } }),
      _c(
        "div",
        { staticClass: "sticky-top" },
        [_c("HeaderNavi", { attrs: { isSignin: _vm.isSignin } })],
        1
      ),
      _vm.isDrawerShow
        ? _c("HeaderDrawer", {
            attrs: {
              isSignin: _vm.isSignin,
              payment: _vm.payment,
              isDispFlagPaying: _vm.isDispFlagPaying
            },
            on: { close: _vm.closeDrawerModal }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main-container" },
        [_c("router-view", { ref: "routerView" })],
        1
      ),
      _c("div", { staticClass: "footer-container" }, [_c("Footer")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }