<template lang="pug">
  transition(name="modal" appear="")
    .modal#modal-overlay(@click="$emit('close')")
      #modal-window
        #modal-window-conteiner
          #header
            #name-area
              //-ユーザー名
              .drawer-menu-user-name(v-if="isSignin")
                p {{ this.$user.userName }}
              .trial-message(v-if="payment=='free'") <small>【 １ヶ月無料期間中 】</small>
              //-ログイン
              .login-link(v-if="!isLoginPage && !isSignin")
                router-link(:to="{ path: '/login', query: { url:this.$route.fullPath }}") ログイン
            #button-area
              //-閉じるボタン
              a#close-button(href="#" @click="$emit('close')")
                i.fas.fa-times
          #menu
            //-メニューList
            .drawer-menu-list
              .menu-item
                .top-menu(@click="callUserMyPage()" v-if="isSignin")
                  i.fas.fa-user &nbsp;マイページ
                .top-menu(v-if="isSignin")
                  router-link(to="/notice/noticeList") 
                    i.fas.fa-bell &nbsp;お知らせ
                      b-badge(variant="info" v-if="isNoticeBadgeShow") {{unReadNotice}}
                .top-menu
                  router-link(to="/users/userSearch")
                    i.fas.fa-search &nbsp;ユーザー検索
              .menu-item(v-if="isSignin")
                .li-header
                  i.fas.fa-cog &nbsp;アカウント設定
                li
                  router-link(to="/user/edit/input") 登録情報の確認・修正
                li
                  router-link(to="/user/password/input") パスワード変更
                li
                  router-link(to="/user/mail/send_auth") メールアドレス変更
                li
                  router-link(to="/user/payment/add_input" v-if="(payment == null || payment == 'free')") 有料プランに変更
                  router-link(to="/user/payment/info" v-else) 支払情報

              .menu-item
                .li-header
                  i.fas.fa-book &nbsp;ガイド
                li
                  router-link(to="/info/user_guide") ご利用ガイド
                li
                  router-link(to="/info/faq") FAQ
                li
                  router-link(to="/info/terms") 利用規約
                li
                  router-link(to="/inquiry_form/input") お問い合わせ
                li
                  router-link(to="/info/company") 運営会社
                li
                  router-link(to="/info/legalnotice") 免責事項
                li
                  router-link(to="/info/law") 特定商取引法の表示
                li
                  router-link(to="/info/privacy") プライバシーポリシー
              ul.menu-item
                li
                  a(@click.prevent="exec" href='#' v-if="isSignin") ログアウト
      div
        b-modal(id="error-modal" title="システムエラー" ok-only)
          div システムエラーが発生しました。
          div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
            router-link(to="/inquiry_form/input") こちら
            |よりお問い合わせください。
</template>

<script>
export default {
  data() {
    return {
      isLoginPage: this.$route.path == '/login',
      unReadNotice: '',
      isNoticeBadgeShow:false
    }
  },
  props: ['isSignin','payment'],
  mounted() {
    this.unReadNotice = this.$parent.unReadNotice
    if(this.unReadNotice != ''){
      this.isNoticeBadgeShow = true
    }
  },
  created() {
    document.body.classList.add('modal-open')
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
  },
  // メソッド
  methods: {
    //ログアウト処理
    exec() {
      try {
        this.$user.cognito.signOut()
      } catch(e) {
        this.$bvModal.show('error-modal')
        return
      }
      this.$router.push('/logout')
    },
    callUserMyPage(item){
      this.$router.push('/myPage/' + this.$user.userId +'/userInfoTop')
    },
  }
}
</script>

<style lang="stylus" scoped>

//----------------------------------
//オーバーレイのトランジション
//----------------------------------
.modal-enter-active, .modal-leave-active {
  transition opacity 0.2s
  // オーバーレイに包含されているモーダルウィンドウのトランジション
  #modal-window {
    transition opacity 0.1s, transform 0.1s
  }
}
.modal-enter, .modal-leave-to {
  opacity 0
  #modal-window {
    opacity 0
    transform translateX(100%)
  }
}

//--------------------
//コンテンツ内のstyle
//--------------------
.modal#modal-overlay
  //右寄せ
  display flex
  align-items flex-end
  justify-content flex-end
  top 0
  left 0
  width 100%
  background rgba(0, 0, 0, 0.5)

  #modal-window
    position absolute
    top 0
    right 0
    background rgba(0, 0, 0, 0.8)
    width 100%
    #modal-window-conteiner,#menu,.drawer-menu-list
      background #171D32
    #modal-window-conteiner
      //縦スクロールさせる為の設定
      height 100vh
      width 70%
      position absolute
      right 0
      color #FFFFFF
      #header
        display flex
        justify-content space-between
        width 100%
        #name-area
          padding 1.8rem 0 0 1rem
          font-size 0.9rem
          width 100%
          .trial-message
            color #d0cf0a
          .login-link
            a
              display block
              color #00b0ff
            a:hover
              color #FFFFFF
              background #006fbe
              text-decoration none !important
        #button-area
          width 20%
          text-align right
          font-size 2.5rem
          padding 0.2rem 1.6rem 0 0
          a
            color #FFFFFF
      //ドロワーメニュー
      #menu
        height 100vh
        margin-top 1rem
        //ドロワーメニュー
        .drawer-menu-list
          .menu-item
            margin-top 0.5rem
            padding-left 1rem
            .top-menu
              font-size 1.5rem
              font-weight normal
              height 3rem
              i
                display block
                width 100%
                height 3rem
                line-height 3rem
              i:hover
                background #006fbe
            .li-header
              font-size 1.2rem
              font-weight normal
              color #7f8b93
            li
              padding-left 1rem
              list-style none
            a
              color #fff
              display block
              height 2.2rem
              line-height 2.2rem
            a:hover
              background #006fbe
              text-decoration none !important

@media screen and (min-width:540px) 
  .modal#modal-overlay #modal-window #modal-window-conteiner
    width 50%

@media screen and (min-width:720px) 
  .modal#modal-overlay #modal-window #modal-window-conteiner
    width 40%

@media screen and (min-width:960px) 
  .modal#modal-overlay #modal-window #modal-window-conteiner
    width 30%

@media screen and (min-width:1140px) 
  .modal#modal-overlay #modal-window #modal-window-conteiner
    width 25%

</style>