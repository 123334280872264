<template lang="pug">
  #header-navi
    //-モーダル↓↓↓↓↓--------------------------
    //- チェックインモーダル
    Checkin(v-if="showCheckin" @closeDialog="showCheckin=false" @levelUp="showLevelUpMsg" @checkin="checkinMessage" )
    //-モーダル↑↑↑↑↑--------------------------
    #sticky-header
      #nav-button-container
        .nav-button-item(@click="callMyPage()" v-if="isSignin")
          .icon
            i.fas.fa-user
          .label マイページ
        .nav-button-item(@click="callMapSearchPage()")
          .icon
            i.fas.fa-map-marked-alt
          .label 地図から探す
        router-link.nav-button-item(to="/rocky/search")
          .icon
            i.fas.fa-search
          .label 岩場を探す
        //-ログイン時
        router-link.nav-button-item(to="/record/list" v-if="isSignin")
          .icon
            i.fas.fa-edit
          .label 記録 
        .nav-button-item.check-in(@click="callChackin" v-if="isSignin")
          .icon
            span.material-icons where_to_vote
          .label チェックイン
        //-未ログイン時
        router-link.nav-button-item(to="/register_user/send_auth" v-if="!isSignin")
          .icon
            i.fas.fa-user-plus
          .label 新規ユーザー登録
        .nav-button-item(@click="callRouterPushLogin()" v-if="!isSignin")
          .icon
            i.fas.fa-sign-in-alt
          .label ログイン
      //------------------------
      //-チェックイン完了時の表示
      //------------------------
      transition(name="fade")
        p.message.text-center.mb-0(v-if="showCheckinMessage")  {{ this.time  }} {{this.checkinName}} にチェックインしました！
      //------------------------
      //-レベルアップ時のダイアログ表示
      //------------------------
      b-modal#levelUp-modal(hide-footer hide-header)
        .text-center
          h1.levelUp レベルアップ！！！
          img(:src="materialURL + iconPath")
          .levelUp-red おめでとうございます！
          .levelUp-red.mb-5 新しいバッジをゲットしました。
          input.btn.btn-primary.mb-3.col(type="submit" @click="hideModal()" value="OK")
      //-有料プラン限定時のモーダル
      b-modal#payment-Error-modal(@hidden="hideErrorModal" hide-footer hide-header)
        h2.my-3.text-center 有料プラン限定機能です。
        div.my-3 有料プランにご登録頂けますと、岩場情報やルート写真の参照、登攀記録の作成が可能になります。
        .text-center
          div
            input.btn.btn-primary.mb-3(type="submit" @click.prevent="callAddPayment" value="有料プランへ変更") 
          div
            input.btn.btn-secondary.mb-3(type="submit" value="戻る" @click.prevent="$bvModal.hide('payment-Error-modal')")
      //- ネットワークエラーモーダル
      b-modal#network-Error-modal(@hidden="hideErrorModal" hide-footer hide-header)
        .text-center
          h4.my-3 ネットワークエラー
          div.my-3 ネットワークエラーが発生し、情報の取得に失敗しました
          input.btn.btn-secondary.mb-3(type="submit" value="戻る" @click.prevent="$bvModal.hide('network-Error-modal')")
</template>

<script>
import axios from 'axios'
import Checkin from '@/components/checkin/add'

export default {
  data(){
    return {
      isLoginPage: this.$route.path == '/login',
      //モーダル画面の表示制御に使用
      showCheckin: false,
      showCheckinMessage: false,
      time: '',
      checkinName: '',
      drawerFlg: false,
      timer: null,
      iconPath: '',
      materialURL: process.env.VUE_APP_MATERIAL_URL,
      isNotBack: false
    }
  },
  props: ['isSignin'],
  methods: {
    callRouterPushLogin(){
      if(this.$route.path == '/register_user/send_auth') {
        this.$router.push({ name: 'login', query: { url: '/'}})
      }else if(this.$route.path != '/login') {
        this.$router.push({ name: 'login', query: { url:this.$route.fullPath }})
      }
    },
    checkinMessage(name) {

      //トップページかマイページを表示している場合、ページ内のデータ再取得する
      if(this.$route.path=='/' || this.$route.path.match('/myPage')){
        this.reloadMyPage()
      }

      this.showCheckinMessage = true

      //日付取得
      let date = new Date () 

      let hour = ('0' + date.getHours()).slice(-2) 	// 時
      let minute = ('0' + date.getMinutes()).slice(-2)	// 分
      
      this.time = '' + hour + ':' + minute 

      //岩場名セット
      this.checkinName = name
      
      clearTimeout(this.timer)

      //メッセージ7秒間表示
      this.timer = setTimeout(() => {
        this.showCheckinMessage = false
      },7000)
    },
    showLevelUpMsg(name,iconPath) {
      this.showCheckin=false
      this.checkinName = name
      this.iconPath = iconPath
      this.$bvModal.show('levelUp-modal')
    },
    hideModal(rockName) {
      this.$bvModal.hide('levelUp-modal')
      this.checkinMessage(this.checkinName)
    },
    //有料会員登録ページに移動
    callAddPayment() {
      this.isNotBack = true
      this.$bvModal.hide('payment-Error-modal')
      this.$router.push('/user/payment/add_input')
    },
    //前のページに戻る
    hideErrorModal() {
      if(!this.isNotBack) {
        this.$router.go(-1)
      }
      this.isNotBack = false
    },
    //チェックインモーダル表示判定
    callChackin() {
      if(this.$user.payment) {
        this.showCheckin = true
      }else if(!this.$user.payment) {
        if(navigator.onLine) {
          this.$bvModal.show('payment-Error-modal')
        } else {
          this.$bvModal.show('network-Error-modal')
        }
        this.isNotBack = true
      }
    },
    callMyPage(){
      //NavigationDuplicatedエラーを防ぐ為に無名関数を実装
      this.$router.push('/myPage/' + this.$user.userId +'/userInfoTop', () => {}, () => {})
    },
    //マイページの再読み込み
    reloadMyPage(){
      this.$parent.$refs.routerView.reloadMyPage()
    },
    callMapSearchPage(){
      //TOP画面のMAPの中央位置とzoomの保持を解除する
      localStorage.isLastMapCentervalid = false
      this.$router.push('/rocky/map_search')
    }
  },
  // コンポーネント
  components: {
    Checkin
  },
}
</script>

<style lang="stylus" scoped>
  //検索ボタン、記録、チェックインボタン
  #nav-button-container
    display flex
    width 100%
    background #f9f9fa
    .nav-button-item
      width 25%
      text-align center
      background #171d32
      color #DCDDE0
      cursor pointer
      &:active,&:hover 
        background-color #006fbe
        color #f9f9fa
        text-decoration none
        .icon
          color white
      .icon
        color #DCDDE0
        height 40px
        font-size 25px
      .label
        font-size 0.5rem
        padding 0px 0 10px 0
    .check-in
      color #D0CF0A
      font-weight 700 
      .icon
        span
          color #D0CF0A
          padding-top 7px
          font-size 27px 
      &:hover,&:active
        color #D0CF0A
//チェックイン時の表示
.message
  width 100%
  background #d0cf0a
.fade-enter-active, .fade-leave-active 
  transition opacity .5s
.fade-enter, .fade-leave-to 
  opacity 0

</style>