var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "footer-wrap" } }, [
    _c("div", { staticClass: "container-fluid", attrs: { id: "footer" } }, [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-12 col-lg-3" }, [
          _c("div", { staticClass: "innner-block" }, [
            _c("div", { staticClass: "innner-header" }, [_vm._v("ABOUT")]),
            _c(
              "ul",
              [
                _c("router-link", { attrs: { to: "/info/user_guide" } }, [
                  _c("p", [_vm._v("ご利用ガイド")])
                ]),
                _c("router-link", { attrs: { to: "/info/faq" } }, [
                  _c("p", [_vm._v("FAQ")])
                ]),
                _c("router-link", { attrs: { to: "/info/terms" } }, [
                  _c("p", [_vm._v("利用規約")])
                ]),
                _c("router-link", { attrs: { to: "/info/legalnotice" } }, [
                  _c("p", [_vm._v("免責事項")])
                ])
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "col-12 col-lg-3" }, [
          _c("div", { staticClass: "innner-block" }, [
            _c("div", { staticClass: "innner-header" }, [_vm._v("COMPANY")]),
            _c(
              "ul",
              [
                _c("router-link", { attrs: { to: "/info/company" } }, [
                  _c("p", [_vm._v("運営会社")])
                ]),
                _c("router-link", { attrs: { to: "/info/law" } }, [
                  _c("p", [_vm._v("特定商取引法の表示")])
                ]),
                _c("router-link", { attrs: { to: "/info/privacy" } }, [
                  _c("p", [_vm._v("プライバシーポリシー")])
                ])
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "col-12 col-lg-3" }, [
          _c("div", { staticClass: "innner-block" }, [
            _c("div", { staticClass: "innner-header" }, [_vm._v("ROCKY")]),
            _c(
              "ul",
              [
                _c("router-link", { attrs: { to: "/rocky/all_rocky_list" } }, [
                  _c("p", [_vm._v("岩場一覧")])
                ])
              ],
              1
            ),
            _c("div", { staticClass: "innner-header" }, [_vm._v("CONTACT")]),
            _c(
              "ul",
              [
                _c("router-link", { attrs: { to: "/feed_back/input" } }, [
                  _c("p", [_vm._v("不具合・岩情報の報告")])
                ]),
                _c("router-link", { attrs: { to: "/inquiry_form/input" } }, [
                  _c("p", [_vm._v("お問い合わせ")])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-lg-3" }, [
      _c("div", { staticClass: "innner-block" }, [
        _c("div", { staticClass: "innner-header" }, [_vm._v("提携サイト")]),
        _c("div", { staticClass: "alliance" }, [
          _c(
            "a",
            {
              attrs: { href: "https://villars.co.jp/ariake", target: "_blank" }
            },
            [
              _c("img", {
                attrs: { src: require("../assets/Villars_logo.png") }
              })
            ]
          ),
          _c("p", [_vm._v("VILLARS株式会社")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center", attrs: { id: "copyright" } },
      [_c("small", [_vm._v("© Robinson Co., Ltd. All Rights Reserved.")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }