import cognitoUtil from '@/commons/cognitoUtil'

export default {
  created() {
  },
  methods: {
    //岩場のサムネイル画像のパスを返す
    getRockyThumbPath : function(path:string,size:string) {      
      //画像無しの場合
      if (path.match('no_img.png')) {
        return process.env.VUE_APP_MATERIAL_URL + '/img/rp/no_img.png'      
      }
      let fileName = path.split('/').slice(-1)[0].replace('.jpg', '')
      const tmpPath = '/img/rp/thumb/' + fileName + '_w' + size + '.jpg'
      return process.env.VUE_APP_MATERIAL_URL + tmpPath
    },
    //岩のサムネイル画像のパスを返す
    getRockThumbPath : function(path:string,rockyId:string,size:string) { 
      //画像無しの場合
      if ( path.match('no_img.png')) {
        return process.env.VUE_APP_MATERIAL_URL + '/img/rk/no_img.png'  
      }
      let fileName = path.split('/').slice(-1)[0].replace('.jpg', '')
      let tmpRockyId = ('0000' + rockyId).slice(-4)
      const tmpPath = '/img/rk/' + tmpRockyId + '/thumb/' + fileName + '_w' + size + '.jpg'
      return process.env.VUE_APP_MATERIAL_URL + tmpPath
    },
    //ステータスバッチアイコンのパスを返す
    getStatusIconPath : function(path:string) { 
      //画像無しの場合
      if ( path.match('no_img.png')) {
        return process.env.VUE_APP_MATERIAL_URL + '/img/rk/no_img.png'  
      }
      return process.env.VUE_APP_MATERIAL_URL + path
    },  
  },
  computed: {
  }
}