import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  ICognitoUserPoolData,
  ICognitoStorage
} from 'amazon-cognito-identity-js'

/*
* Cognitoユーティリティークラス
*/
export default class CognitoUtil {
  constructor() {
  }

  // セッション取得
  getSession(): Promise<CognitoUser> {

    return new Promise<CognitoUser>((resolve: (value?: CognitoUser) => void, reject: (err?: any) => void) => {
      
      let poolData: ICognitoUserPoolData = {
        UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID || '',
        ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID || '',
        Storage: localStorage as ICognitoStorage
      }
    
      // ローカルストレージから取得
      let userPool: CognitoUserPool = new CognitoUserPool(poolData)
      let cognitoUser: CognitoUser | null = userPool.getCurrentUser()

      if (cognitoUser == null) {
        // セッションストレージから取得
        poolData.Storage = sessionStorage as ICognitoStorage
        userPool = new CognitoUserPool(poolData)
        cognitoUser = userPool.getCurrentUser()
      }

      if (cognitoUser == null) {
        resolve(undefined)
        return
      }

      cognitoUser.getSession((err: any) => {
        if (err) resolve(undefined)
        resolve(cognitoUser!)
      })

    })
  }
}