var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: { id: "modal-overlay" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [
        _c("div", { attrs: { id: "modal-window" } }, [
          _c("div", { attrs: { id: "modal-window-conteiner" } }, [
            _c("div", { attrs: { id: "header" } }, [
              _c("div", { attrs: { id: "name-area" } }, [
                _vm.isSignin
                  ? _c("div", { staticClass: "drawer-menu-user-name" }, [
                      _c("p", [_vm._v(_vm._s(this.$user.userName))])
                    ])
                  : _vm._e(),
                _vm.payment == "free"
                  ? _c("div", { staticClass: "trial-message" }, [
                      _c("small", [_vm._v("【 １ヶ月無料期間中 】")])
                    ])
                  : _vm._e(),
                !_vm.isLoginPage && !_vm.isSignin
                  ? _c(
                      "div",
                      { staticClass: "login-link" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/login",
                                query: { url: this.$route.fullPath }
                              }
                            }
                          },
                          [_vm._v("ログイン")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("div", { attrs: { id: "button-area" } }, [
                _c(
                  "a",
                  {
                    attrs: { id: "close-button", href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]),
            _c("div", { attrs: { id: "menu" } }, [
              _c("div", { staticClass: "drawer-menu-list" }, [
                _c("div", { staticClass: "menu-item" }, [
                  _vm.isSignin
                    ? _c(
                        "div",
                        {
                          staticClass: "top-menu",
                          on: {
                            click: function($event) {
                              return _vm.callUserMyPage()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-user" }, [
                            _vm._v(" マイページ")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.isSignin
                    ? _c(
                        "div",
                        { staticClass: "top-menu" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/notice/noticeList" } },
                            [
                              _c(
                                "i",
                                { staticClass: "fas fa-bell" },
                                [
                                  _vm._v(" お知らせ"),
                                  _vm.isNoticeBadgeShow
                                    ? _c(
                                        "b-badge",
                                        { attrs: { variant: "info" } },
                                        [_vm._v(_vm._s(_vm.unReadNotice))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "top-menu" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/users/userSearch" } },
                        [
                          _c("i", { staticClass: "fas fa-search" }, [
                            _vm._v(" ユーザー検索")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm.isSignin
                  ? _c("div", { staticClass: "menu-item" }, [
                      _c("div", { staticClass: "li-header" }, [
                        _c("i", { staticClass: "fas fa-cog" }, [
                          _vm._v(" アカウント設定")
                        ])
                      ]),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/user/edit/input" } },
                            [_vm._v("登録情報の確認・修正")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/user/password/input" } },
                            [_vm._v("パスワード変更")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/user/mail/send_auth" } },
                            [_vm._v("メールアドレス変更")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _vm.payment == null || _vm.payment == "free"
                            ? _c(
                                "router-link",
                                { attrs: { to: "/user/payment/add_input" } },
                                [_vm._v("有料プランに変更")]
                              )
                            : _c(
                                "router-link",
                                { attrs: { to: "/user/payment/info" } },
                                [_vm._v("支払情報")]
                              )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "menu-item" }, [
                  _c("div", { staticClass: "li-header" }, [
                    _c("i", { staticClass: "fas fa-book" }, [_vm._v(" ガイド")])
                  ]),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/info/user_guide" } }, [
                        _vm._v("ご利用ガイド")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/info/faq" } }, [
                        _vm._v("FAQ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/info/terms" } }, [
                        _vm._v("利用規約")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/inquiry_form/input" } },
                        [_vm._v("お問い合わせ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/info/company" } }, [
                        _vm._v("運営会社")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/info/legalnotice" } },
                        [_vm._v("免責事項")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/info/law" } }, [
                        _vm._v("特定商取引法の表示")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/info/privacy" } }, [
                        _vm._v("プライバシーポリシー")
                      ])
                    ],
                    1
                  )
                ]),
                _c("ul", { staticClass: "menu-item" }, [
                  _c("li", [
                    _vm.isSignin
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.exec($event)
                              }
                            }
                          },
                          [_vm._v("ログアウト")]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c(
          "div",
          [
            _c(
              "b-modal",
              {
                attrs: {
                  id: "error-modal",
                  title: "システムエラー",
                  "ok-only": ""
                }
              },
              [
                _c("div", [_vm._v("システムエラーが発生しました。")]),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりお問い合わせください。")
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }