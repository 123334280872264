var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-navi" } },
    [
      _vm.showCheckin
        ? _c("Checkin", {
            on: {
              closeDialog: function($event) {
                _vm.showCheckin = false
              },
              levelUp: _vm.showLevelUpMsg,
              checkin: _vm.checkinMessage
            }
          })
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "sticky-header" } },
        [
          _c(
            "div",
            { attrs: { id: "nav-button-container" } },
            [
              _vm.isSignin
                ? _c(
                    "div",
                    {
                      staticClass: "nav-button-item",
                      on: {
                        click: function($event) {
                          return _vm.callMyPage()
                        }
                      }
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "label" }, [
                        _vm._v("マイページ")
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "nav-button-item",
                  on: {
                    click: function($event) {
                      return _vm.callMapSearchPage()
                    }
                  }
                },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "label" }, [_vm._v("地図から探す")])
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "nav-button-item",
                  attrs: { to: "/rocky/search" }
                },
                [
                  _c("div", { staticClass: "icon" }, [
                    _c("i", { staticClass: "fas fa-search" })
                  ]),
                  _c("div", { staticClass: "label" }, [_vm._v("岩場を探す")])
                ]
              ),
              _vm.isSignin
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-button-item",
                      attrs: { to: "/record/list" }
                    },
                    [
                      _c("div", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-edit" })
                      ]),
                      _c("div", { staticClass: "label" }, [_vm._v("記録 ")])
                    ]
                  )
                : _vm._e(),
              _vm.isSignin
                ? _c(
                    "div",
                    {
                      staticClass: "nav-button-item check-in",
                      on: { click: _vm.callChackin }
                    },
                    [
                      _vm._m(2),
                      _c("div", { staticClass: "label" }, [
                        _vm._v("チェックイン")
                      ])
                    ]
                  )
                : _vm._e(),
              !_vm.isSignin
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-button-item",
                      attrs: { to: "/register_user/send_auth" }
                    },
                    [
                      _c("div", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-user-plus" })
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v("新規ユーザー登録")
                      ])
                    ]
                  )
                : _vm._e(),
              !_vm.isSignin
                ? _c(
                    "div",
                    {
                      staticClass: "nav-button-item",
                      on: {
                        click: function($event) {
                          return _vm.callRouterPushLogin()
                        }
                      }
                    },
                    [
                      _vm._m(3),
                      _c("div", { staticClass: "label" }, [_vm._v("ログイン")])
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showCheckinMessage
              ? _c("p", { staticClass: "message text-center mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(this.time) +
                      " " +
                      _vm._s(this.checkinName) +
                      " にチェックインしました！"
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                id: "levelUp-modal",
                "hide-footer": "",
                "hide-header": ""
              }
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c("h1", { staticClass: "levelUp" }, [
                  _vm._v("レベルアップ！！！")
                ]),
                _c("img", { attrs: { src: _vm.materialURL + _vm.iconPath } }),
                _c("div", { staticClass: "levelUp-red" }, [
                  _vm._v("おめでとうございます！")
                ]),
                _c("div", { staticClass: "levelUp-red mb-5" }, [
                  _vm._v("新しいバッジをゲットしました。")
                ]),
                _c("input", {
                  staticClass: "btn btn-primary mb-3 col",
                  attrs: { type: "submit", value: "OK" },
                  on: {
                    click: function($event) {
                      return _vm.hideModal()
                    }
                  }
                })
              ])
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "payment-Error-modal",
                "hide-footer": "",
                "hide-header": ""
              },
              on: { hidden: _vm.hideErrorModal }
            },
            [
              _c("h2", { staticClass: "my-3 text-center" }, [
                _vm._v("有料プラン限定機能です。")
              ]),
              _c("div", { staticClass: "my-3" }, [
                _vm._v(
                  "有料プランにご登録頂けますと、岩場情報やルート写真の参照、登攀記録の作成が可能になります。"
                )
              ]),
              _c("div", { staticClass: "text-center" }, [
                _c("div", [
                  _c("input", {
                    staticClass: "btn btn-primary mb-3",
                    attrs: { type: "submit", value: "有料プランへ変更" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.callAddPayment($event)
                      }
                    }
                  })
                ]),
                _c("div", [
                  _c("input", {
                    staticClass: "btn btn-secondary mb-3",
                    attrs: { type: "submit", value: "戻る" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$bvModal.hide("payment-Error-modal")
                      }
                    }
                  })
                ])
              ])
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "network-Error-modal",
                "hide-footer": "",
                "hide-header": ""
              },
              on: { hidden: _vm.hideErrorModal }
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c("h4", { staticClass: "my-3" }, [
                  _vm._v("ネットワークエラー")
                ]),
                _c("div", { staticClass: "my-3" }, [
                  _vm._v("ネットワークエラーが発生し、情報の取得に失敗しました")
                ]),
                _c("input", {
                  staticClass: "btn btn-secondary mb-3",
                  attrs: { type: "submit", value: "戻る" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$bvModal.hide("network-Error-modal")
                    }
                  }
                })
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-map-marked-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("span", { staticClass: "material-icons" }, [_vm._v("where_to_vote")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-sign-in-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }