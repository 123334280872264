<template lang="pug">
  #header-component
    b-navbar(toggleable="lg" type="dark" variant="info")
      b-navbar-brand
        #logo
          #logo-box
            router-link(to="/") 
              img(src="../assets/logo.png" alt="")
          #trial-message(v-if="payment=='free'") 【 １ヶ月無料期間中 】
      #right-area
        #notice-box(@click="callRouterPush('/notice/noticeList')" v-if="isSignin")
          //-お知らせアイコン
          i.fas.fa-bell
          #notice-badge(v-if="isNoticeBadgeShow") {{unreadNoticeCount}}
        #toggle-box(@click="openDrawerModal")
          //-ハンバーガーボタン
          span
      #pc-menu.float-right
        b-collapse.justify-content-end#nav-collapse(is-nav="") 
          b-navbar-nav
            b-nav-item(to="/notice/noticeList" v-if="isSignin") お知らせ
              b-badge(v-if="isNoticeBadgeShow" variant="info") {{unreadNoticeCount}}
            b-nav-item-dropdown(text="アカウント設定" right="" v-if="isSignin")
              b-dropdown-item(to="/user/edit/input") 情報の確認・修正
              b-dropdown-item(to="/user/password/input") パスワード変更
              b-dropdown-item(to="/user/mail/send_auth") メールアドレス変更
              b-dropdown-item(v-if="(payment == null || payment == 'free')" to="/user/payment/add_input") 有料プランに変更
              b-dropdown-item(v-else to="/user/payment/info") 支払情報
            b-nav-item(to="/users/userSearch") ユーザー検索
            b-nav-item-dropdown(text="利用ガイド" right="")
              b-dropdown-item(to="/info/user_guide") ご利用ガイド
              b-dropdown-item(to="/info/faq") FAQ
              b-dropdown-item(to="/info/terms") 利用規約
              b-dropdown-item(to="/inquiry_form/input") お問い合わせ
              b-dropdown-item(to="/info/company") 運営会社
              b-dropdown-item(to="/info/legalnotice") 免責事項
              b-dropdown-item(to="/info/law") <small>特定商取引法の表示</small>
              b-dropdown-item(to="/info/privacy") <small>プライバシーポリシー</small>
            b-nav-item(@click="exec" href='#' v-if="!isLoginPage & isSignin") ログアウト
      div
        b-modal(id="error-modal" title="システムエラー" ok-only)
          div システムエラーが発生しました。
          div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
            router-link(to="/inquiry_form/input") こちら
            |よりお問い合わせください。
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return {
      isDispFlagPaying:false,
      isDrawerShow:false,
      isLoginPage: this.$route.path == '/login',
      isFirst:true,
      unreadNoticeCount:'',
      isNoticeBadgeShow:false
    }
  },
  props: ['isSignin', 'payment'],
  watch: {
    // ルートの変更の検知
    '$route' (to, from) {
      //お知らせ取得
      if(this.isFirst){
        this.getUnreadNoticeCount()
      }
    
      //前回の取得時間より5分以上経過している場合、お知らせを再度取得する。
      //ブラウザのページ(タブ)がアクティブの場合のみ処理する
      if (document.visibilityState === 'visible') {
        if(new Date().getTime() - new Date(localStorage.noticeGetDateTime).getTime() > 300000){
          this.getUnreadNoticeCount()
        }
      }

      // PCでのスクロール禁止解除
      document.removeEventListener("mousewheel", this.scroll_control, { passive: false });
      // スマホでのタッチ操作でのスクロール禁止解除
      document.removeEventListener('touchmove', this.scroll_control, { passive: false });

    },
    //App.vueで保持している未読件数をwatchする
    '$parent.unReadNotice' (to, from){
      this.unreadNoticeCount = to
      if(this.unreadNoticeCount==''){
        //表示対象が存在しない場合はバッジを非表示にする
        this.isNoticeBadgeShow = false
      }
    }
  },
  methods: {
    //ログアウト処理
    exec() {
      try {
        this.$user.cognito.signOut()
      } catch(e) {
        this.$bvModal.show('error-modal')
        return
      }
      this.$router.push('/logout')
    },
    //----------------------------
    //未読のお知らせの件数を取得する
    //----------------------------
    async getUnreadNoticeCount() {

      console.log('get unread notice ---' + this.$user.isLogin)

      //未ログインの場合は何もしない
      if(!this.$user.isLogin){
        return
      }

      let searchKeyword = ''
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-notice/unread-notice'
      await axios
        .get(jsonUrl,{
          params: {
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {

          console.log( 'unread notice ---' + response.data)

          if(response.data>0){
            //3桁以上の場合は3点リーダー表示する
            if(response.data>99){
              this.unreadNoticeCount = '…'
              this.$parent.unReadNotice = '…'
            }else{
              this.unreadNoticeCount = response.data
              this.$parent.unReadNotice = response.data
            }

            this.isNoticeBadgeShow = true
          }

          //ローカルストレージにお知らせ取得した時間をセットする
          localStorage.noticeGetDateTime = new Date()
        })
        .catch(err => {
          // alert(error)
        })
        this.isFirst = false

    },
    openDrawerModal(){
      this.$parent.openDrawerModal()
    },
    callRouterPush(path){
      //NavigationDuplicatedエラーを防ぐ為に無名関数を実装
      this.$router.push(path, () => {}, () => {})
    },

  },
  // コンポーネント
  components: {
  },
}
</script>

<style lang="stylus" scoped>
#header-component

  //デフォルト色を上書き
  .bg-info
    background #171d32 !important
  position relative

  //ロゴ
  #logo
    display flex
    #logo-box
      img
        width 170px
        height 48px
    #trial-message
      height 48px
      padding 22px 0 0 1rem
      color #d0cf0a
      font-size 0.7rem

  //通知アイコン、ドロワー
  #right-area
    position absolute
    right 1rem
    display flex
    #notice-box
      font-size 1.5rem
      color #FFF
      padding-top 2px
      padding-right 2rem
      position relative
      #notice-badge
        position absolute
        top 20px
        right 20px
        width 20px
        height 16px
        border-radius 5px 
        background #17A2B8
        text-align center
        font-size 0.6rem
    #toggle-box
      width 40px
      height 40px
      padding 12px 25px 0 0
      position relative
      cursor pointer
      span,span:before,span:after
        position absolute
        height 2px
        width 24px
        border-radius 3px
        background #FFF
        display block
        content ''
      span:before
        bottom -8px
      span:after
        bottom -16px

  //PC用メニュー
  #pc-menu
    position absolute
    right 1rem
    .navbar-dark .navbar-toggler
      border none

    .nav-item
      padding 0
      font-size 0.8rem
      text-align center
      width 120px
      a
        color #FFF      

    .nav-item:hover,.nav-item:active
      background #006fbe

@media screen and (max-width:991px)
  #trial-message
    display none    

@media screen and (min-width:992px)
  #header-component
    #toggle-box,#notice-box
      display none
    #pc-menu
      .nav-item
        width 120px

@media screen and (min-width:1120px)
  #header-component
    #pc-menu
      .nav-item
        font-size 1rem
        width 140px

@media screen and (min-width:1200px)
  #header-component
    #pc-menu
      .nav-item
        width 150px

</style>