<template lang="pug">    
  #app
    Header(:isSignin="isSignin" :payment="payment")
    .sticky-top
      HeaderNavi(:isSignin="isSignin")
    HeaderDrawer(@close="closeDrawerModal" v-if="isDrawerShow" :isSignin="isSignin" :payment="payment" :isDispFlagPaying="isDispFlagPaying")
    .main-container
      //-HeaderNaviComponent.vueから$ref参照する為、ref指定している
      router-view(ref="routerView")
    .footer-container
      Footer
</template>

<script>
import Header from '@/components/HeaderComponent.vue'
import HeaderDrawer from '@/components/HeaderDrawerComponent.vue'
import HeaderNavi from '@/components/HeaderNaviComponent.vue'
import Footer from '@/components/FooterComponent.vue'

export default {
  data(){
    return {
      isSignin: false,
      isDrawerShow:false,
      payment:'',
      isDispFlagPaying:false,
      //ヘッダーの各コンポーネントから参照される未読お知らせ件数
      unReadNotice:''
    }
  },
  watch: {
    // ルートの変更の検知
    '$route' (to, from) {
      this.isSignin = this.$user.isLogin
      this.payment = this.$user.payment
      // ハンバーガーメニューが開いている場合は閉じる
      if(this.isDrawerShow){
        this.closeDrawerModal()
      }
    }
  },
  // コンポーネント
  components: {
    Header,
    HeaderNavi,
    HeaderDrawer,
    Footer
  },
  methods: {
    openDrawerModal(){
      this.isDrawerShow = true
    },
    closeDrawerModal(){
      this.isDrawerShow = false
    },
  }
}
</script>

<style lang="stylus">
#app
  color #2c3e50
  font-family 'Noto Sans JP', sans-serif;

  //ヘッダー
  .dropdown-menu 
    //ヘッダーのdropdownstickyヘッダーより前面に表示する
    z-index 99999 !important
    background #171d32 !important
    b-nav-item:hover
      color red !important
      background #006fbe !important

    .dropdown-item
      color #FFFFFF !important
      background #171d32 !important
    .dropdown-item:hover
      background #006fbe !important
  .navbar-dark .navbar-nav .nav-link
    span
      color #FFF !important
  //ハンバーガーボタン(三本線)の色      
  .navbar-dark .navbar-toggler-icon {
    background-image: 
      url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(250, 250, 250, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  // ユーザー管理系レイアウト調整
  .user-manage
    .container
      margin-bottom 2rem
    h1,h2,h3,h4,h5,h6
      color #2c3e50
    h1
      font-size 1.8rem
      color #0664a9
      font-weight normal
      margin 1.5rem 0
      text-align center

  //info見出し
  .info
    .container
      margin-top 3rem
      margin-bottom 1rem
      min-height 50vh
    h1,h2,h3,h4,h5,h6  
      color #2c3e50
      text-align center
    h2
      padding 1.5rem 0
    section
      margin-bottom 1.5rem
  //パンくず
  .breadcrumb
    background rgba(0,0,0,0)
    margin 1rem 0
    padding 0
    width 100%
    a
      color #6c757d
      text-decoration underline
    li + li:before 
      content: "\f105" !important; 
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    .breadcrumb-item::before,.active
      color #6c757d

  //検索系画面
  .button-blue
    display inline-block
    text-align center
    background-color #0664A9
    color #fff
    text-decoration none
    font-size 1rem
    height 40px
    width 100%
    border-style hidden
    box-shadow 2px 2px 4px #000
    i.fas
      color #fff
      font-size 20px
      line-height 20px
  .button-blue:hover
    opacity 0.9
    transition-duration 0.3s
  //記録参照系画面
  .record-title
    background linear-gradient(#094d80,#171d32)
    padding 1rem
    text-align center
    h5,h6
      color #FFF
  // bootstrap
  .btn-primary
    background #0664a9
    border none
  .btn-primary:hover
    background #076DB5
  .btn
    box-shadow 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)
    -webkit-tap-highlight-color transparent
    transition .3s ease-out
  .btn:hover
    box-shadow 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2)
  .btn:active
    opacity .8

  //テーブルのソートアイコン
  .table.b-table > thead > tr > [aria-sort="none"],
  .table.b-table > tfoot > tr > [aria-sort="none"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .table.b-table > thead > tr > [aria-sort="ascending"],
  .table.b-table > tfoot > tr > [aria-sort="ascending"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .table.b-table > thead > tr > [aria-sort="descending"],
  .table.b-table > tfoot > tr > [aria-sort="descending"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

// ラジオボタン
.form-check-input[type=radio]
  display none
  &:checked + .radio-label::after
    opacity 1
  &:disabled
    + .radio-label::before
      border 2px solid #6c757d
    + .radio-label::after
      background #6c757d
.radio-label
  box-sizing border-box
  cursor pointer
  display inline-block
  padding 2px 10px 0px 25px
  position relative
  width auto
  &::before
    background #fff
    border 2px solid #2699FB
    border-radius 50%
    content ''
    display block
    height 18px
    left 4px
    margin-top -7px
    position absolute
    top 49%
    width 18px
  &::after
    background #2699FB
    border-radius 50%
    content ''
    display block
    height 10px
    left 8px
    margin-top -4px
    opacity 0
    position absolute
    top 52%
    width 10px

//マイページ タブの共通設定
.container-fluid
  .tab-main-container
    margin-left -15px
    margin-right -15px
    padding-bottom 2rem
    .headline
      font-size 1.2rem
      background #004170
      color #FFF
      padding-left 1rem

//コンテンツの高さに関わらずフッターを最下部に表示する為の設定
  .main-container
    min-height calc(100vh - 500px)
  .footer-container
    height 500px
//PC表示
@media (min-width 992px) 
  .main-container
    min-height calc(100vh - 255px)
  .footer-container
    height 235px

//PC表示
@media (min-width 1170px) 
  #app
    background-color #fff
    h1,h2,h3,h4,h5,h6
      color #000
    .wrapper
      background-color white
      padding-left 10%
      padding-right 10%
    //パンくず
    .breadcrumb
      a
        color #000
      .breadcrumb-item::before,.active
        color #000

    //規約系ページ
    .info
      .container
        margin-top 5rem  
</style>
